(function($) {

  function initStickyNav(){
    $('#site-navigation').sticky({
      topSpacing:0,
      //getWidthFrom: '#nav-width',
      responsiveWidth: true,
      //widthFromWrapper: false,
      center: true
    });

    $('#sticky-menu-block').sticky({topSpacing:100});
  }

  function initMmenu(){
    var isOpen = false;

    $('#site-navigation').mmenu({

    }, {
      clone: true,
      offCanvas: {
        pageSelector: "#page"
       }
    });

    var API = $('#mm-site-navigation').data( 'mmenu' );

    $('#menu-toggle').on('click', function(){
      if(!isOpen) {
        API.open();
      } else {
        API.close();
      }

      isOpen = !isOpen;
    })
  }

  $(document).ready(function(){
    // make stickynavigation
    initStickyNav();

    // init mobile menu
    initMmenu();
  });
})( jQuery );
